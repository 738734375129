new WOW({ offset: 70 }).init();
$(document).ready(function () {

	if ($("#HomepageMarker").length === 0) {
		$(".header__logo, .header__weather-sun").css("display", "block");
	}

	else {
		$(".categorylinks__link").addClass("wow fadeIn").attr({ "data-wow-duration": "1.5s", "data-wow-offset": "120" });
		$(".header__weather-sun").addClass("wow fadeIn").css("display", "block");
		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__logo").addClass("wow zoomIn").css("display", "block");
		} else {
			$(".header__logo").css("display", "block");
		}

	}

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$(".home-gallery__previous").click(function (e) {
		var img = $(".home-gallery__slide").first();
		img.animate({ width: 0 }, 1000, "linear", function () {
			img.remove();
			$(".home-gallery__inner").append(img);
			img.width(140);
		});
		e.preventDefault();
		return false;
	});

	$(".home-gallery__next").click(function (e) {
		var img = $(".home-gallery__slide").last();
		img.width(0);
		img.remove();
		$(".home-gallery__inner").prepend(img);
		img.animate({ width: 140 }, 1000, "linear");
		e.preventDefault();
		return false;
	});

	function fixHeights() {
		$('.testimonials__container').sameHeight();
		$('.testimonials .item').sameHeight();
		$('.testimonials__link').sameHeight();
		$('.featured-categories .carousel').sameHeight();
	}

	setTimeout(fixHeights, 300);
	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});
});
